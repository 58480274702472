<template>
  <div v-loading.fullscreen.lock="loading" class="mod-config">
    <div class="topArea">
      <div class="topLeftArea">
        <div class="span">1</div>
        <div class="title">裁切试题</div>
        <hr />
        <div class="span unset" @click="linkTo(2)">2</div>
        <div class="title">标注知识点</div>
        <template v-if="isResMode === 0">
          <hr />
          <div class="span unset" @click="linkTo(3)">3</div>
          <div class="title">匹配变式题</div>
        </template>
      </div>
      <div class="topRightArea">
        <el-button class="btn-style" type="primary" @click="saveExamPaperDivide"
          >完 成</el-button
        >
      </div>
    </div>
    <div class="bottomArea">
      <div class="title">
        <span>{{ examPaper.name || "" }}</span
        ><span>科目：{{ examPaper.subjectId | setName(subjectOptions) }}</span>
      </div>
      <div class="bottomLeftArea fl">
        <div class="examPaperPicArea">
          <img
            id="examPaperPic"
            class="examPaperPic"
            :src="currentPicURL"
            @load="initCropper"
          />
        </div>
        <el-pagination
          small
          :page-size="1"
          :current-page="picPageIndex"
          layout="prev, pager, next"
          :total="picPageTotal"
          @current-change="picCurrentChange"
        >
        </el-pagination>
        <!--浮动按钮-->
        <div class="floatingButtonBar" :style="floatingButtonBarProp">
          <div
            class="cancelCropButton"
            title="取消"
            @click="examPaperPicCropSelectionCancel"
          >
            <i class="el-icon-close" />
          </div>
          <div
            class="cropButton"
            title="裁切"
            @click="examPaperPicCropSelection"
          >
            <i class="el-icon-scissors" />
          </div>
        </div>
      </div>
      <div class="bottomMiddleArea fl">
        <div class="iconButton gapButton" @click="examPaperPicFullscreen">
          <i class="el-icon-zoom-in" title="全屏"></i>
        </div>
        <div class="iconButton notButton">
          <i
            class="el-icon-info"
            title="双击图片可在裁剪模式与拖动模式之间进行切换，滑动鼠标滚轮可放大缩小图片"
          ></i>
        </div>
        <div class="iconButton" @click="examPaperPicZoomOut">
          <i class="el-icon-minus" title="缩小"></i>
        </div>
        <div class="iconButton" @click="examPaperPicZoomIn">
          <i class="el-icon-plus" title="放大"></i>
        </div>
        <div class="iconButton" @click="examPaperPicRotateLeft">
          <i class="el-icon-refresh-left" title="左旋"></i>
        </div>
        <div class="iconButton" @click="examPaperPicRotateRight">
          <i class="el-icon-refresh-right" title="右旋"></i>
        </div>
        <div class="iconButton" @click="examPaperPicRotateCustom">
          <i class="el-icon-setting" title="自定义旋转"></i>
        </div>
        <div class="iconButton" @click="examPaperPicReset">
          <i class="el-icon-refresh" title="重置"></i>
        </div>
        <div class="iconButton" @click="examPaperPicCrop">
          <i class="el-icon-crop" title="裁剪"></i>
        </div>
        <div class="iconButton" @click="examPaperPicClear">
          <i class="el-icon-close" title="关闭"></i>
        </div>
      </div>
      <div class="bottomRightArea fr">
        <div class="questionArea">
          <template v-for="(item, index) in examQuestionData">
            <div :key="index" class="questionBox">
              <div class="questionTitle">
                <div class="questionNum">{{ item.qNum }}</div>
                <div class="questionButton">
                  <i
                    class="el-icon-edit"
                    title="编辑题号"
                    @click.stop="clickEditQuestionNum(item.id)"
                  ></i>
                </div>
              </div>
              <div class="questionContent">
                <div class="questionItemTitle">
                  {{ item.isMaterial === 1 ? "材料" : "" }}
                </div>
                <el-select
                  v-model="item.subjectId"
                  class="questionTypeSelect"
                  placeholder="请选择科目"
                  style="width: 80px"
                  @change="changeSubject(item)"
                >
                  <el-option
                    v-for="option in schoolSubjectOptions"
                    :key="option.value"
                    :label="option.label"
                    :value="option.value"
                  >
                  </el-option>
                </el-select>
                <el-select
                  v-model="item.type"
                  class="questionTypeSelect"
                  placeholder="请选择题型"
                  style="width: 90px; margin-left: 5px"
                  @change="changeType(item.epqId)"
                >
                  <el-option
                    v-for="option in examQuestionTypeOptions[item.subjectId]"
                    :key="option.value"
                    :label="option.label"
                    :value="option.value"
                  >
                  </el-option>
                </el-select>
                <div class="questionButtonDiv">
                  <el-button type="text" @click.stop="display(item.id)">{{
                    displayButton(item.display)
                  }}</el-button
                  ><el-button type="text" @click.stop="clickDelete(item.id)"
                    >删除</el-button
                  >
                </div>
              </div>
              <div
                v-if="item.display === 1"
                class="questionImgContent"
                v-html="item.displayContent"
              ></div>
              <!--材料题小题-->
              <template v-for="(subItem, subIndex) in item.subQuestions">
                <div :key="subIndex" class="subQuestionBox">
                  <div class="questionContent">
                    <div class="questionItemTitle">
                      <span>小题：{{ subItem.qNum }}</span>
                      <span class="questionTitleEdit">
                        <i
                          class="el-icon-edit"
                          title="编辑题号"
                          @click.stop="clickEditQuestionNum(subItem.id)"
                        ></i>
                      </span>
                    </div>
                    <div class="questionButtonDiv">
                      <el-button
                        type="text"
                        @click.stop="display(subItem.id)"
                        >{{ displayButton(subItem.display) }}</el-button
                      ><el-button
                        type="text"
                        @click.stop="clickDelete(subItem.id)"
                        >删除</el-button
                      >
                    </div>
                  </div>
                  <div
                    v-if="subItem.display === 1"
                    class="questionImgContent"
                    v-html="subItem.displayContent"
                  ></div>
                </div>
              </template>
            </div>
          </template>
        </div>
      </div>
    </div>
    <!--全屏弹窗-->
    <el-dialog :visible.sync="picFSDialogVisible" :fullscreen="true">
      <img width="100%" :src="currentPicURL" />
    </el-dialog>
    <!--划题弹窗-->
    <el-dialog
      title="划题"
      :visible.sync="optionDialogVisible"
      :lock-scroll="false"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
      width="500px"
    >
      <el-form label-width="80px">
        <el-form-item label-width="70px">
          <el-radio v-model="selectType" :label="0">材料</el-radio>
          <el-radio v-model="selectType" :label="1">试题</el-radio>
          <el-radio v-model="selectType" :label="2"
            >已划取试题追加图片</el-radio
          >
        </el-form-item>
        <template v-if="selectType === 0 || selectType === 1">
          <el-form-item label="题号">
            <el-input v-model="latestQuestionNum"></el-input>
          </el-form-item>
          <el-form-item
            v-if="
              selectType === 0 ||
              (selectType === 1 && latestIsMaterialSub === 0)
            "
            label="科目"
          >
            <el-select
              v-model="latestSubjectId"
              placeholder="请选择科目"
              @change="changeDividePopupSubject"
            >
              <el-option
                v-for="item in schoolSubjectOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            v-if="
              selectType === 0 ||
              (selectType === 1 && latestIsMaterialSub === 0)
            "
            label="题型"
          >
            <el-select v-model="latestQuestionType" placeholder="请选择题型">
              <el-option
                v-for="item in examQuestionTypeOptions[latestSubjectId]"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item v-if="selectType === 1" label="是否为材料题小题">
            <el-checkbox
              v-model="latestIsMaterialSub"
              :true-label="1"
              :false-label="0"
            ></el-checkbox>
          </el-form-item>
          <el-form-item
            v-if="selectType === 1 && latestIsMaterialSub === 1"
            label="该小题所属材料题"
          >
            <el-select
              v-model="latestMaterialSubQuestionParentId"
              placeholder="请选择该小题所属材料题"
            >
              <el-option
                v-for="item in examMaterialQuestionOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </template>
        <template v-else-if="selectType === 2">
          <el-form-item label="试题">
            <el-select
              v-model="latestSelectedQuestionId"
              placeholder="请选择试题"
            >
              <el-option
                v-for="item in examQuestionIdNumOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </template>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button class="cancelButton" @click="cancelOptionDialog"
          >取 消</el-button
        >
        <el-button class="confirmButton" @click="confirmOptionDialog"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <!--题号重命名弹窗-->
    <el-dialog
      :visible.sync="qNumDialogVisible"
      :lock-scroll="false"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
      width="500px"
    >
      <el-form label-width="80px">
        <el-form-item label="题号">
          <el-input v-model="latestQuestionNum"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button class="cancelButton" @click="cancelQNumDialog"
          >取 消</el-button
        >
        <el-button class="confirmButton" @click="confirmQNumDialog"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <!--自定义旋转弹窗-->
    <el-dialog
      :visible.sync="rotateCustomDialogVisible"
      :lock-scroll="false"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
      width="300px"
    >
      <el-form label-width="80px">
        <el-form-item label="旋转方向">
          <el-radio v-model="rotateDirection" :label="1">左旋</el-radio>
          <el-radio v-model="rotateDirection" :label="2">右旋</el-radio>
        </el-form-item>
        <el-form-item label="旋转度数">
          <el-input v-model="rotateDegree" type="number"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button class="cancelButton" @click="cancelRotateCustomDialog"
          >取 消</el-button
        >
        <el-button class="confirmButton" @click="confirmRotateCustomDialog"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import $ from "jquery";
import Cropper from "cropperjs";
import "cropperjs/dist/cropper.css";
// 服务端各接口需要判断是否有 考试管理相关权限 或 资源中心个人试卷相关权限
import {
  getExamPaperDivideInfo,
  convertBase64toIMGandUpload,
  saveExamPaperDivide,
  getExamQuestionTypeByLevel,
} from "@/core/api/exam/exampaper";
import { keyName, setStore, removeStore, getAllStore } from "@/core/util/store";
import { subjectOptions } from "@/core/util/constdata";
import { getSubjectList } from "@/core/util/util";

export default {
  data() {
    return {
      subjectOptions: subjectOptions,

      isResMode: 0, // 当前是否为资源中心上传试卷后进行切题 0-否 1-是
      examId: 0,
      examLevel: 0,
      examPaper: {},
      examPaperId: 0,
      examSubjectId: 0,
      examPaperPics: [],
      currentPicURL: "",
      picPageIndex: 1,
      picPageTotal: 10,
      cropper: {},
      cropperInitialized: false, // cropper对象是否已经初始化
      currentCropperDetail: {},
      picFSDialogVisible: false,
      hasDivided: false,
      examQuestionData: [], // 切题数据 [ { epqId: 1, eqId: 1, id: 1, qNum: "第1题", type: 1, isMaterial: 0, content: "", display: 0, displayContent: "", subQuestions: [] } ]
      examQuestionCount: 0, // 切题计数 只增不减。用于给 examQuestionData 里的 id 赋值。
      optionDialogVisible: false,
      qNumDialogVisible: false,
      rotateCustomDialogVisible: false,
      selectType: 1, // 划题类型 0-材料 1-试题 2-已划取试题追加图片
      latestQuestionId: 0,
      latestQuestionNum: "",
      latestSubjectId: "",
      latestQuestionType: "",
      latestIsMaterialSub: 0,
      latestMaterialSubQuestionParentId: "",
      latestSelectedQuestionId: "",
      rotateDirection: 1, // 旋转方向 1-左旋 2-右旋
      rotateDegree: 90, // 旋转度数 0-360之间
      loading: false,
      CDN: "https://static.wtjy.com/",
      schoolSubjectOptions: [],
      examQuestionTypeOptions: {},
      examQuestionIdNumOptions: [], // 当前所有划出的题（包括子题） { value: id, label: qNum }
      examMaterialQuestionOptions: [], // 当前划出的材料题题干 { value: id, label: qNum }
      floatingButtonBarProp: { top: "-999px", left: "-999px" },
      keyName: keyName, // store.js 下的 keyName ，本地存储前缀
      storePrefix: "examPaperDivide-", // 试卷切题数据的本地存储前缀
      useStore: false,
    };
  },
  computed: {
    ...mapGetters(["layoutConfig"]),
  },
  created() {
    if (
      !this.$route.query ||
      Object.keys(this.$route.query).length === 0 ||
      !this.$route.query.id
    ) {
      this.$message.error("没有参数！");
      return;
    }
    if (this.$route.name === "resexampaperpicdivide") {
      this.isResMode = 1;
    }
    this.loading = true;
    this.$store.commit("setIsPicDivide", true);
    // 获取传过来的参数
    this.examPaperId = this.$route.query.id;
    // 获取试卷对象及试卷转换后的图片
    getExamPaperDivideInfo(this.examPaperId).then((response) => {
      let data = response.data.data;
      this.examId = data.examId;
      this.examLevel = data.level;
      this.examSubjectId = data.subjectId;
      this.examPaper = data.examPaper;
      this.examPaperPics = data.examPaperPics;
      if (!this.examPaperPics || this.examPaperPics.length === 0) {
        this.$message.error("上传的试卷正在转换处理中！");
        return;
      }
      // 科目默认选择本试卷的科目ID
      this.latestSubjectId = data.subjectId;
      // 获取本校本学段的科目
      let subjectOptions = getSubjectList(this.examLevel);
      for (let i = 0; i < subjectOptions.length; i++) {
        let subjectData = subjectOptions[i];
        this.schoolSubjectOptions.push({
          value: subjectData.value,
          label: subjectData.label,
        });
      }
      // 题型
      this.getQuestionType();
      // 载入图片
      this.currentPicURL = this.CDN + this.examPaperPics[0];
      $("#examPaperPic").attr("src", this.currentPicURL);
      this.picPageTotal = this.examPaperPics.length;
      this.loading = false;
      if (data.examPaperDivideData.examQuestionData.length > 0) {
        // 试卷已经切题
        this.hasDivided = true;
        this.examQuestionData = data.examPaperDivideData.examQuestionData;
        this.examQuestionCount = data.examPaperDivideData.examQuestionCount;
        this.examQuestionIdNumOptions =
          data.examPaperDivideData.examQuestionIdNumOptions;
        for (let i = 0; i < this.examQuestionData.length; i++) {
          let examQuestion = this.examQuestionData[i];
          examQuestion.content = decodeURIComponent(examQuestion.content);
          examQuestion.displayContent = decodeURIComponent(
            examQuestion.displayContent
          );
          if (examQuestion.subQuestions) {
            for (let j = 0; j < examQuestion.subQuestions.length; j++) {
              let subExamQuestion = examQuestion.subQuestions[j];
              subExamQuestion.content = decodeURIComponent(
                subExamQuestion.content
              );
              subExamQuestion.displayContent = decodeURIComponent(
                subExamQuestion.displayContent
              );
            }
          }
        }
      } else {
        // 试卷还未切题
        // 检查浏览器 Web Storage 是否有未保存切题数据
        let examPaperDivideStoreData;
        let storeList = getAllStore({});
        for (let i = 0; i < storeList.length; i++) {
          let store = storeList[i];
          if (
            store.name &&
            store.name.substring(
              0,
              (this.keyName + this.storePrefix).length
            ) ===
              this.keyName + this.storePrefix
          ) {
            let id = Number(
              store.name.substring((this.keyName + this.storePrefix).length)
            );
            if (id === this.examPaper.id) {
              // 找到当前试卷的切题数据
              examPaperDivideStoreData = store.content;
            } else {
              // 移除其他试卷的切题数据
              removeStore({ name: this.storePrefix + id });
            }
          }
        }
        if (examPaperDivideStoreData) {
          this.$confirm(
            "检测到之前未保存的切题数据，是否恢复？<br/>注意：不会恢复知识点，变式题等",
            "提示",
            {
              dangerouslyUseHTMLString: true,
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "info",
            }
          )
            .then(() => {
              this.useStore = true;
              this.examQuestionData = examPaperDivideStoreData.examQuestionData;
              this.examQuestionCount =
                examPaperDivideStoreData.examQuestionCount;
              this.examQuestionIdNumOptions =
                examPaperDivideStoreData.examQuestionIdNumOptions;
            })
            .catch(() => {});
        }
      }
    });
  },
  mounted() {
    // 设置 questionArea 区域样式
    let screenHeight = $(window).outerHeight(true);
    let headerHeight = $("#kt_header").outerHeight(true);
    let contentPaddingTop = Number(
      $("#kt_content").css("paddingTop").replace("px", "")
    );
    let topAreaHeight = $(".topArea").outerHeight(true);
    let questionAreaHeight =
      screenHeight - headerHeight - contentPaddingTop - topAreaHeight;
    $(".questionArea").css("height", questionAreaHeight + "px");
    $(document).on("scroll.eppd", () => {
      let scrollTop = $(document).scrollTop();
      let contentHeight = $("#kt_content").outerHeight(true);
      let bottomCritical = contentHeight - (screenHeight - headerHeight); // 底部即将出现footer的临界值
      if (scrollTop <= contentPaddingTop + topAreaHeight) {
        questionAreaHeight =
          screenHeight -
          headerHeight -
          (contentPaddingTop + topAreaHeight - scrollTop);
        $(".questionArea").css("height", questionAreaHeight + "px");
        $(".questionArea").css("position", "unset");
        $(".questionArea").css("top", "unset");
      } else if (
        scrollTop > contentPaddingTop + topAreaHeight &&
        scrollTop <= bottomCritical
      ) {
        questionAreaHeight = screenHeight - headerHeight;
        $(".questionArea").css("height", questionAreaHeight + "px");
        $(".questionArea").css("position", "fixed");
        $(".questionArea").css("top", headerHeight + "px");
      } else if (scrollTop > bottomCritical) {
        questionAreaHeight =
          screenHeight - headerHeight - (scrollTop - bottomCritical);
        $(".questionArea").css("height", questionAreaHeight + "px");
        $(".questionArea").css("position", "fixed");
        $(".questionArea").css("top", headerHeight + "px");
      }
    });
  },
  destroyed() {
    $(document).off("scroll.eppd");
    this.$store.commit("setIsPicDivide", false);
  },
  methods: {
    linkTo(step) {
      if (step === 2 || step === 3) {
        if (!this.hasDivided) {
          this.$message.error("该试卷还未切题！");
          return;
        }
        if (this.examPaper.statusAutoMatch === 0) {
          this.$message.error(
            "服务器正在对已切试题进行自动匹配知识点和变式题的操作！稍后请重试。"
          );
          return;
        }
      }
      if (step === 2) {
        // 标注知识点
        if (this.isResMode === 1) {
          this.$router.push({
            name: "resexamlore",
            query: { id: this.examPaper.id },
          });
        } else {
          this.$router.push({
            name: "examlore",
            query: { id: this.examPaper.id },
          });
        }
      } else if (step === 3) {
        // 锁定变式题
        this.$message.error("不能直接进行后面的步骤！");
      }
    },
    // 图片页码点击事件
    picCurrentChange(index) {
      this.cropper.destroy();
      this.cropperInitialized = false;
      this.currentPicURL = this.CDN + this.examPaperPics[index - 1];
    },
    initCropper() {
      // 初始化图片裁剪插件
      let _this = this;
      let image = document.getElementById("examPaperPic");
      this.cropper = new Cropper(image, {
        dragMode: "move",
        modal: false,
        ready() {
          _this.resetCropper();
        },
        crop(event) {
          _this.currentCropperDetail = event.detail;
        },
        cropstart() {
          _this.floatingButtonBarProp.top = "-999px";
          _this.floatingButtonBarProp.left = "-999px";
        },
        cropend() {
          let data = _this.cropper.getCropBoxData();
          _this.floatingButtonBarProp.top = data.top + data.height + 5 + "px";
          _this.floatingButtonBarProp.left = data.left + data.width + 5 + "px";
        },
      });
      this.cropperInitialized = true;
    },
    resetCropper() {
      if (this.cropperInitialized) {
        this.cropper.reset();
        this.cropper.setData({
          x: 50,
          y: 50,
          width: 300,
          height: 300,
          rotate: 0,
          scaleX: 1,
          scaleY: 1,
        });
        this.floatingButtonBarProp.top = "-999px";
        this.floatingButtonBarProp.left = "-999px";
      }
    },
    examPaperPicFullscreen() {
      this.picFSDialogVisible = true;
    },
    examPaperPicZoomOut() {
      if (this.cropperInitialized) {
        this.cropper.zoom(-0.1);
      }
    },
    examPaperPicZoomIn() {
      if (this.cropperInitialized) {
        this.cropper.zoom(0.1);
      }
    },
    examPaperPicRotateLeft() {
      if (this.cropperInitialized) {
        this.cropper.rotate(-1);
      }
    },
    examPaperPicRotateRight() {
      if (this.cropperInitialized) {
        this.cropper.rotate(1);
      }
    },
    examPaperPicRotateCustom() {
      if (this.cropperInitialized) {
        this.rotateCustomDialogVisible = true;
      }
    },
    examPaperPicReset() {
      if (this.cropperInitialized) {
        this.resetCropper();
      }
    },
    examPaperPicCrop() {
      if (this.cropperInitialized) {
        this.cropper.crop();
        this.cropper.setData({
          x: 50,
          y: 50,
          width: 300,
          height: 300,
          rotate: 0,
          scaleX: 1,
          scaleY: 1,
        });
        this.floatingButtonBarProp.top = "-999px";
        this.floatingButtonBarProp.left = "-999px";
      }
    },
    examPaperPicClear() {
      if (this.cropperInitialized) {
        this.cropper.clear();
        this.floatingButtonBarProp.top = "-999px";
        this.floatingButtonBarProp.left = "-999px";
      }
    },
    examPaperPicCropSelectionCancel() {
      this.floatingButtonBarProp.top = "-999px";
      this.floatingButtonBarProp.left = "-999px";
    },
    cancelRotateCustomDialog() {
      this.clearLatestValues();
      this.rotateCustomDialogVisible = false;
    },
    confirmRotateCustomDialog() {
      if (!this.rotateDegree || isNaN(this.rotateDegree)) {
        this.$message.error("请输入正确的数字！");
        return;
      }
      if (this.rotateDegree < 0 || this.rotateDegree > 360) {
        this.$message.error("请输入0-360范围内的数字！");
        return;
      }
      if (this.rotateDirection === 1) {
        // 左旋
        this.cropper.rotate(-this.rotateDegree);
      } else if (this.rotateDirection === 2) {
        // 右旋
        this.cropper.rotate(this.rotateDegree);
      }
      this.clearLatestValues();
      this.rotateCustomDialogVisible = false;
    },
    examPaperPicCropSelection() {
      this.examQuestionCount++;
      this.latestQuestionId = this.examQuestionCount;
      this.latestQuestionNum = this.calculateQuestionNum();
      this.optionDialogVisible = true;
      this.buildExamMaterialQuestionOptions();
    },
    calculateQuestionNum() {
      let totalCount = 0;
      for (let i = 0; i < this.examQuestionData.length; i++) {
        totalCount++;
        if (!this.examQuestionData[i].subjectOptions) {
          continue;
        }
        if (this.examQuestionData[i].subQuestions.length > 0) {
          totalCount += this.examQuestionData[i].subQuestions.length;
        }
      }
      totalCount++;
      return totalCount.toString();
    },
    buildExamMaterialQuestionOptions() {
      this.examMaterialQuestionOptions = [];
      for (let i = 0; i < this.examQuestionData.length; i++) {
        if (this.examQuestionData[i].isMaterial === 1) {
          this.examMaterialQuestionOptions.push({
            value: this.examQuestionData[i].id,
            label: this.examQuestionData[i].qNum,
          });
        }
      }
    },
    async getCropAreaBASE64Image() {
      let BASE64Image = this.cropper
        .getCroppedCanvas({
          fillColor: "#fff",
          imageSmoothingEnabled: false,
          imageSmoothingQuality: "high",
        })
        .toDataURL("image/png");
      let data = new FormData();
      data.append("base64Str", encodeURIComponent(BASE64Image));
      let imgUrl = "";
      await convertBase64toIMGandUpload(data).then((response) => {
        imgUrl = response.data.data;
      });
      let content =
        "<p style='padding:0;margin:0;vertical-align:middle;border:navajowhite;text-align:left;align-items:center;line-height:1.5;word-break:break-all;'>" +
        '<img class="jzjxImg" style=\'padding:0;margin:0;vertical-align:middle;border:navajowhite;' +
        "width:" +
        this.getPtByPx(this.currentCropperDetail.width) +
        "pt;" +
        "height:" +
        this.getPtByPx(this.currentCropperDetail.height) +
        "pt;" +
        "'" +
        " src='" +
        imgUrl +
        "' />" +
        "</p>";
      let displayContent =
        "<p style='padding:0;margin:0;vertical-align:middle;border:navajowhite;text-align:left;align-items:center;line-height:1.5;word-break:break-all;'>" +
        "<img style='padding:0;margin:0;vertical-align:middle;border:navajowhite;" +
        "max-width:311px;" +
        "'" +
        " src='" +
        imgUrl +
        "' />" +
        "</p>";
      return [content, displayContent];
    },
    getPtByPx(px) {
      let dpi = 108; // 服务器PDF转图片设置的DPI为108
      return (px * 72) / dpi;
    },
    clickEditQuestionNum(id) {
      this.latestQuestionId = id;
      this.latestQuestionNum = this.getQNumById(id);
      this.qNumDialogVisible = true;
    },
    getQNumById(id) {
      let qNum = "";
      cycle: for (let i = 0; i < this.examQuestionData.length; i++) {
        let examQuestion = this.examQuestionData[i];
        if (examQuestion.id === id) {
          qNum = examQuestion.qNum;
          break;
        }
        if (examQuestion.subQuestions) {
          for (let j = 0; j < examQuestion.subQuestions.length; j++) {
            let subExamQuestion = examQuestion.subQuestions[j];
            if (subExamQuestion.id === id) {
              qNum = subExamQuestion.qNum;
              break cycle;
            }
          }
        }
      }
      return qNum;
    },
    changeSubject(item) {
      item.type = "";
      if (
        this.examQuestionTypeOptions[item.subjectId] &&
        this.examQuestionTypeOptions[item.subjectId].length > 0
      ) {
        // 默认选中该科目第一个题型
        item.type = this.examQuestionTypeOptions[item.subjectId][0].value;
      }
      let epqId = item.epqId;
      cycle: for (let i = 0; i < this.examQuestionData.length; i++) {
        let examQuestion = this.examQuestionData[i];
        if (examQuestion.epqId === epqId) {
          examQuestion.epqId = 0;
          break;
        }
        if (examQuestion.subQuestions) {
          for (let j = 0; j < examQuestion.subQuestions.length; j++) {
            let subExamQuestion = examQuestion.subQuestions[j];
            if (subExamQuestion.epqId === epqId) {
              subExamQuestion.epqId = 0;
              break cycle;
            }
          }
        }
      }
      this.setStore();
    },
    changeType(epqId) {
      cycle: for (let i = 0; i < this.examQuestionData.length; i++) {
        let examQuestion = this.examQuestionData[i];
        if (examQuestion.epqId === epqId) {
          examQuestion.epqId = 0;
          break;
        }
        if (examQuestion.subQuestions) {
          for (let j = 0; j < examQuestion.subQuestions.length; j++) {
            let subExamQuestion = examQuestion.subQuestions[j];
            if (subExamQuestion.epqId === epqId) {
              subExamQuestion.epqId = 0;
              break cycle;
            }
          }
        }
      }
      this.setStore();
    },
    changeDividePopupSubject() {
      this.latestQuestionType = "";
      if (
        this.examQuestionTypeOptions[this.latestSubjectId] &&
        this.examQuestionTypeOptions[this.latestSubjectId].length > 0
      ) {
        // 默认选中该科目第一个题型
        this.latestQuestionType =
          this.examQuestionTypeOptions[this.latestSubjectId][0].value;
      }
    },
    getQuestionType() {
      this.latestQuestionType = "";
      this.examQuestionTypeOptions = {};
      getExamQuestionTypeByLevel({ level: this.examLevel }).then((response) => {
        let resQuestionTypes = response.data.data;
        for (let i = 0; i < resQuestionTypes.length; i++) {
          let type = resQuestionTypes[i];
          let subjectId = type.subjectId;
          let subArr = this.examQuestionTypeOptions[subjectId];
          if (!subArr) {
            subArr = [];
          }
          if (type.parentId === 0) {
            // 主题型
            subArr.push({
              value: type.id,
              label: type.name,
            });
            // 子题型
            for (let j = 0; j < resQuestionTypes.length; j++) {
              let subType = resQuestionTypes[j];
              if (subType.parentId === type.id) {
                subArr.push({
                  value: subType.id,
                  label: type.name + " - " + subType.name,
                });
              }
            }
          }
          this.examQuestionTypeOptions[subjectId] = subArr;
        }
        this.changeDividePopupSubject();
      });
    },
    display(qId) {
      cycle: for (let i = 0; i < this.examQuestionData.length; i++) {
        let examQuestion = this.examQuestionData[i];
        if (examQuestion.id === qId) {
          examQuestion.display = 1 - examQuestion.display;
          this.$set(this.examQuestionData, i, examQuestion);
          break;
        }
        if (examQuestion.subQuestions) {
          for (let j = 0; j < examQuestion.subQuestions.length; j++) {
            let subExamQuestion = examQuestion.subQuestions[j];
            if (subExamQuestion.id === qId) {
              subExamQuestion.display = 1 - subExamQuestion.display;
              this.$set(examQuestion.subQuestions, j, subExamQuestion);
              break cycle;
            }
          }
        }
      }
    },
    clickDelete(qId) {
      this.deleteExamQuestion(qId);
    },
    deleteExamQuestion(qId) {
      cycle: for (let i = 0; i < this.examQuestionData.length; i++) {
        let examQuestion = this.examQuestionData[i];
        if (examQuestion.id === qId) {
          this.examQuestionData.splice(i, 1);
          break;
        }
        if (examQuestion.subQuestions) {
          for (let j = 0; j < examQuestion.subQuestions.length; j++) {
            let subExamQuestion = examQuestion.subQuestions[j];
            if (subExamQuestion.id === qId) {
              examQuestion.subQuestions.splice(j, 1);
              break cycle;
            }
          }
        }
      }
      this.buildExamQuestionIdNumOptions();
      this.setStore();
    },
    cancelOptionDialog() {
      this.clearLatestValues();
      this.optionDialogVisible = false;
    },
    async confirmOptionDialog() {
      if (this.selectType === 0 || this.selectType === 1) {
        // 新增材料或试题
        if (!this.latestQuestionNum || this.latestQuestionNum.length === 0) {
          this.$message.error("请输入题号！");
          return;
        }
        if (this.isThisQuestionNumUsed(this.latestQuestionNum)) {
          this.$message.error("该题号已使用，请更换！");
          return;
        }
        if (
          (this.selectType === 0 ||
            (this.selectType === 1 && this.latestIsMaterialSub === 0)) &&
          (!this.latestSubjectId || this.latestSubjectId.length === 0)
        ) {
          this.$message.error("请选择科目！");
          return;
        }
        if (
          (this.selectType === 0 ||
            (this.selectType === 1 && this.latestIsMaterialSub === 0)) &&
          (!this.latestQuestionType || this.latestQuestionType.length === 0)
        ) {
          this.$message.error("请选择题型！");
          return;
        }
        if (this.selectType === 1 && this.latestIsMaterialSub === 1) {
          // 材料题小题
          if (
            !this.latestMaterialSubQuestionParentId ||
            this.latestMaterialSubQuestionParentId.length === 0
          ) {
            this.$message.error("请选择该小题所属材料题！");
            return;
          }
          for (let i = 0; i < this.examQuestionData.length; i++) {
            if (
              this.examQuestionData[i].id ===
              this.latestMaterialSubQuestionParentId
            ) {
              let contentArr = await this.getCropAreaBASE64Image();
              this.examQuestionData[i].subQuestions.push({
                epqId: 0,
                id: this.latestQuestionId,
                qNum: this.latestQuestionNum,
                subjectId: this.latestSubjectId,
                type: 0,
                isMaterial: 1,
                content: contentArr[0],
                display: 0,
                displayContent: contentArr[1],
              });
              break;
            }
          }
        } else {
          // 材料题材料 或 普通试题
          let contentArr = await this.getCropAreaBASE64Image();
          this.examQuestionData.push({
            epqId: 0,
            id: this.latestQuestionId,
            qNum: this.latestQuestionNum,
            subjectId: this.latestSubjectId,
            type: this.latestQuestionType,
            isMaterial: this.selectType === 0 ? 1 : 0,
            content: contentArr[0],
            display: 0,
            displayContent: contentArr[1],
            subQuestions: [],
          });
        }
      } else if (this.selectType === 2) {
        // 已划取试题追加图片
        if (
          !this.latestSelectedQuestionId ||
          this.latestSelectedQuestionId.length === 0
        ) {
          this.$message.error("请选择要插入的试题！");
          return;
        }
        let contentArr = await this.getCropAreaBASE64Image();
        cycle: for (let i = 0; i < this.examQuestionData.length; i++) {
          let examQuestion = this.examQuestionData[i];
          if (examQuestion.id === this.latestSelectedQuestionId) {
            examQuestion.epqId = 0;
            examQuestion.content += contentArr[0];
            examQuestion.displayContent += contentArr[1];
            break;
          }
          if (examQuestion.subQuestions.length > 0) {
            for (let j = 0; j < examQuestion.subQuestions.length; j++) {
              if (
                examQuestion.subQuestions[j].id ===
                this.latestSelectedQuestionId
              ) {
                examQuestion.subQuestions[j].epqId = 0;
                examQuestion.subQuestions[j].content += contentArr[0];
                examQuestion.subQuestions[j].displayContent += contentArr[1];
                break cycle;
              }
            }
          }
        }
      }
      this.buildExamQuestionIdNumOptions();
      this.clearLatestValues();
      this.optionDialogVisible = false;
      this.floatingButtonBarProp.top = "-999px";
      this.floatingButtonBarProp.left = "-999px";
      this.setStore();
    },
    cancelQNumDialog() {
      this.clearLatestValues();
      this.qNumDialogVisible = false;
    },
    confirmQNumDialog() {
      if (!this.latestQuestionNum || this.latestQuestionNum.length === 0) {
        this.$message.error("请输入题号！");
        return;
      }
      if (
        this.isThisQuestionNumUsed(
          this.latestQuestionNum,
          this.latestQuestionId
        )
      ) {
        this.$message.error("该题号已使用，请更换！");
        return;
      }
      cycle: for (let i = 0; i < this.examQuestionData.length; i++) {
        let examQuestion = this.examQuestionData[i];
        if (examQuestion.id === this.latestQuestionId) {
          examQuestion.epqId = 0;
          examQuestion.qNum = this.latestQuestionNum;
          break;
        }
        if (examQuestion.subQuestions) {
          for (let j = 0; j < examQuestion.subQuestions.length; j++) {
            let subExamQuestion = examQuestion.subQuestions[j];
            if (subExamQuestion.id === this.latestQuestionId) {
              subExamQuestion.epqId = 0;
              subExamQuestion.qNum = this.latestQuestionNum;
              break cycle;
            }
          }
        }
      }
      this.buildExamQuestionIdNumOptions();
      this.clearLatestValues();
      this.qNumDialogVisible = false;
      this.setStore();
    },
    isThisQuestionNumUsed(qNum, qId) {
      let has = false;
      cycle: for (let i = 0; i < this.examQuestionData.length; i++) {
        let examQuestion = this.examQuestionData[i];
        if (examQuestion.qNum === qNum && examQuestion.id !== qId) {
          has = true;
          break;
        }
        if (examQuestion.subQuestions.length > 0) {
          for (let j = 0; j < examQuestion.subQuestions.length; j++) {
            if (
              examQuestion.subQuestions[j].qNum === qNum &&
              examQuestion.subQuestions[j].id !== qId
            ) {
              has = true;
              break cycle;
            }
          }
        }
      }
      return has;
    },
    clearLatestValues() {
      this.selectType = 1;
      this.latestQuestionId = 0;
      this.latestQuestionNum = "";
      this.latestQuestionType = "";
      this.latestIsMaterialSub = 0;
      this.latestMaterialSubQuestionParentId = "";
      this.latestSelectedQuestionId = "";
      this.rotateDirection = 1;
      this.rotateDegree = 90;
    },
    buildExamQuestionIdNumOptions() {
      this.examQuestionIdNumOptions = [];
      for (let i = 0; i < this.examQuestionData.length; i++) {
        let examQuestion = this.examQuestionData[i];
        this.examQuestionIdNumOptions.push({
          value: examQuestion.id,
          label: examQuestion.qNum,
        });
        if (examQuestion.subQuestions.length > 0) {
          for (let j = 0; j < examQuestion.subQuestions.length; j++) {
            this.examQuestionIdNumOptions.push({
              value: examQuestion.subQuestions[j].id,
              label: examQuestion.subQuestions[j].qNum,
            });
          }
        }
      }
    },
    setStore() {
      setStore({
        name: this.storePrefix + this.examPaper.id,
        content: {
          examQuestionData: this.examQuestionData,
          examQuestionCount: this.examQuestionCount,
          examQuestionIdNumOptions: this.examQuestionIdNumOptions,
        },
      });
    },
    displayButton(display) {
      let str = "";
      if (display === 0) {
        str = "显示";
      } else if (display === 1) {
        str = "隐藏";
      }
      return str;
    },
    saveExamPaperDivide() {
      if (this.examQuestionData.length === 0) {
        this.$message.error("请划取试题！");
        return;
      }
      let examQuestions = [];
      let materialIndex = 1;
      for (let i = 0; i < this.examQuestionData.length; i++) {
        let examQuestion = this.examQuestionData[i];
        if (!examQuestion.subjectId || examQuestion.subjectId.length === 0) {
          this.$message.error("有划取的试题未选择科目，请选择！");
          return;
        }
        if (!examQuestion.type || examQuestion.type.length === 0) {
          this.$message.error("有划取的试题未选择题型，请选择！");
          return;
        }
        let examQuestionVO = {};
        examQuestionVO.question = encodeURIComponent(examQuestion.content);
        examQuestionVO.subjectId = examQuestion.subjectId;
        examQuestionVO.type = examQuestion.type;
        examQuestionVO.examPaperNum = examQuestion.qNum;
        examQuestionVO.epqId = examQuestion.epqId;
        if (examQuestion.isMaterial === 1) {
          if (
            examQuestion.subQuestions &&
            examQuestion.subQuestions.length === 0
          ) {
            this.$message.error("试题 " + examQuestion.qNum + " 未划取小题！");
            return;
          }
          examQuestionVO.materialIndex = materialIndex;
          examQuestionVO.parentMaterialIndex = 0;
        } else {
          examQuestionVO.materialIndex = 0;
          examQuestionVO.parentMaterialIndex = 0;
        }
        examQuestions.push(examQuestionVO);
        if (examQuestion.subQuestions) {
          for (let j = 0; j < examQuestion.subQuestions.length; j++) {
            let examQuestionSub = examQuestion.subQuestions[j];
            let examQuestionVOSub = {};
            examQuestionVOSub.question = encodeURIComponent(
              examQuestionSub.content
            );
            examQuestionVOSub.subjectId = examQuestion.subjectId; // 材料题子题的科目直接使用材料的科目
            examQuestionVOSub.type = examQuestion.type; // 材料题子题的题型直接使用材料的题型
            examQuestionVOSub.examPaperNum = examQuestionSub.qNum;
            if (examQuestion.isMaterial === 1) {
              examQuestionVOSub.materialIndex = 0;
              examQuestionVOSub.parentMaterialIndex = materialIndex;
            } else {
              examQuestionVOSub.materialIndex = 0;
              examQuestionVOSub.parentMaterialIndex = 0;
            }
            examQuestionVOSub.epqId = examQuestionSub.epqId;
            examQuestions.push(examQuestionVOSub);
          }
        }
        materialIndex++;
      }
      let data = {};
      data.examId = this.examId;
      data.examLevel = this.examLevel;
      data.examSubjectId = this.examSubjectId;
      data.examPaperId = this.examPaper.id;
      data.examPaperDivideQuestionVOs = examQuestions;
      data.isPicDivide = 1;
      this.loading = true;
      saveExamPaperDivide(data)
        // eslint-disable-next-line no-unused-vars
        .then((response) => {
          this.loading = false;
          // 服务端会对每道题匹配变式训练题和知识点，耗时较长。故不直接进入下一步的页面。
          // if (this.isResMode === 1) {
          //   this.$router.push({
          //     name: "resexamlore",
          //     query: { id: this.examPaper.id }
          //   });
          // } else {
          //   this.$router.push({
          //     name: "examlore",
          //     query: { id: this.examPaper.id }
          //   });
          // }
          if (this.isResMode === 1) {
            // 返回上一页
            this.$router.go(-1);
          } else {
            // 返回试题管理页面
            this.$router.push({
              name: "exampaper",
              query: {
                id: this.examId, // 考试ID
                subId: this.examSubjectId, // 科目ID
              },
            });
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error) => {
          this.loading = false;
          this.$message.error("保存失败！");
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/sass/pages/exam/ExamPaperPicDivide.scss";
</style>

<style lang="scss">
.floatingButtonBar {
  i {
    color: #ffffff;
  }
}
#kt_content.isPicDivide {
  background-color: #d8d8d8;
}
</style>
